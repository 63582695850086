const Contact = () => {
  return (
    <main id="contact">
      <section id="get-in-touch">
        <div className="container">
          <h2>Get In Touch</h2>
          <div className="text">
            I’d love to hear about what you’re working on and how I could help. I’m currently
            looking for a new role and am open to a wide range of opportunities. My preference would
            be to find a remote only position. I’m a hard-working and positive person who will
            always approach each task with a sense of purpose and attention to detail. Please get in
            touch using the form.
          </div>
        </div>
      </section>

      <section id="form">
        <div className="container">
          <h2>Contact Me</h2>
          <form name="contact" method="post">
            {/* <form name="contact" netlify netlify-honeypot="bot-field"> */}
            {/* <form onSubmit={(e) => e.preventDefault()} netlify> */}
            <input type="hidden" name="form-name" value="contact" />
            <label>
              Name
              <input type="text" name="name" placeholder="Jane Appleseed" required />
            </label>
            <label>
              Email Address
              <input type="email" name="email" placeholder="email@example.com" required />
            </label>
            <label>
              Message
              <textarea
                rows="4"
                type="text"
                name="message"
                placeholder="How can I help?"
                required
              />
            </label>
            <button className="btn primary" type="submit">
              Send Message
            </button>
          </form>
        </div>
      </section>
    </main>
  );
};

export default Contact;
