const projects = [
  {
    title: "Planet Facts",
    description: "Build with NextJS and Sass. Animations made with framer motion.",
    slug: "planet-facts",
    heroImage: require("./images/detail/Planets - Hero.png").default,
    portfolioImage: require("./images/detail/Planets - Portfolio.png").default,
    previewImageDesktop: require("./images/detail/Planets - Preview 1.png").default,
    previewImageMobile: require("./images/detail/Planets - Preview 2.png").default,
    details: "",
    learned: "",
    tech: ["", "", ""],
    link: "https://planet-facts-six.vercel.app/",
    code: "https://www.github.com/",
  },
  {
    title: "Audiophile",
    description:
      "Built this multipage e-commerce site using NextJS and styled with Sass. Fully responsive and with a mobile first approach.",
    slug: "audiophile",
    heroImage: require("./images/detail/Audiophile - Hero.png").default,
    portfolioImage: require("./images/detail/Audiophile - Portfolio.png").default,
    previewImageDesktop: require("./images/detail/Audiophile - Preview 1.png").default,
    previewImageMobile: require("./images/detail/Audiophile - Preview 2.png").default,
    details: "",
    learned: "",
    tech: ["", "", ""],
    link: "https://audiophile-beryl.vercel.app/",
    code: "https://www.github.com/",
  },
  {
    title: "Photosnap",
    description: "Converted from sketch design spec, I built this site with ReactJS and Sass",
    slug: "photosnap",
    heroImage: require("./images/detail/Photosnap - Hero.png").default,
    portfolioImage: require("./images/detail/Photosnap - Portfolio.png").default,
    previewImageDesktop: require("./images/detail/Photosnap - Preview 1.png").default,
    previewImageMobile: require("./images/detail/Photosnap - Preview 2.png").default,
    details: "",
    learned: "",
    tech: ["", "", ""],
    link: "https://photosnap-multi-page-website-mu.vercel.app/",
    code: "https://www.github.com/",
  },
  {
    title: "Designo",
    description: "Mobile first site built with NextJS and Sass.",
    slug: "designo",
    heroImage: require("./images/detail/Designo - Hero.png").default,
    portfolioImage: require("./images/detail/Designo - Portfolio.png").default,
    previewImageDesktop: require("./images/detail/Designo - Preview 1.png").default,
    previewImageMobile: require("./images/detail/Designo - Preview 2.png").default,
    details: "",
    learned: "",
    tech: ["", "", ""],
    link: "https://designo-multi-page-website.vercel.app/",
    code: "https://www.github.com/",
  },
  {
    title: "Broker 2 Broker Group",
    description:
      "Real estate broker homepage built with NextJS and Sass. Used mailchimp to collect emails from form.",
    slug: "b2b",
    heroImage: require("./images/detail/B2B - Hero.png").default,
    portfolioImage: require("./images/detail/B2B - Portfolio.png").default,
    previewImageDesktop: require("./images/detail/B2B - Preview 1.png").default,
    previewImageMobile: require("./images/detail/B2B - Preview 2.png").default,
    details: "",
    learned: "",
    tech: ["", "", ""],
    link: "https://www.broker2brokergroup.com/",
    code: "https://www.github.com/",
  },
];

export default projects;

// let description =
//   "This project was built in pure HTML and CSS. I had mobile and desktop designs to work to and built it so that it was fully-responsive. I took a mobile-first approach and used modern CSS like Flexbox and Grid for layout purposes.";
