import { Link } from "react-router-dom";
import CallToAction from "../components/CallToAction";
import projects from "../projects";

const Portfolio = () => {
  const renderProjects = () => {
    return projects.map(({ description, slug, title, portfolioImage }, i) => (
      <div className="project" key={i}>
        <div className="image">
          <img src={portfolioImage} alt="" />
        </div>
        <div className="text">
          <h2>{title}</h2>
          <div>{description}</div>
          <Link to={`/portfolio/${slug}`} className="btn secondary">
            VIEW PROJECT
          </Link>
        </div>
      </div>
    ));
  };

  return (
    <main>
      <section id="portfolio">
        <div className="container">{renderProjects()}</div>
      </section>
      <CallToAction />
    </main>
  );
};

export default Portfolio;
