import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import projects from "../projects";
import CallToAction from "../components/CallToAction";

const Project = () => {
  const { project: projectName } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let p = projects.some((el) => el.slug === projectName);

    if (p) {
      setLoading(false);
    } else {
      return history.push("/");
    }
  }, []);

  let index = projects.findIndex((el) => {
    if (el.slug === projectName) {
      return true;
    }

    return false;
  });

  const renderLinks = () => {
    return (
      <>
        <div className="link">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16">
            <path fill="none" stroke="#33323D" d="M9 0L1 8l8 8" />
          </svg>
          <Link
            to={`/portfolio/${
              index !== 0 ? projects[index - 1].slug : projects[projects.length - 1].slug
            }`}
          >
            <h3>{index !== 0 ? projects[index - 1].title : projects[projects.length - 1].title}</h3>
            <div>Previous Project</div>
          </Link>
        </div>

        <div className="link">
          <Link
            to={`/portfolio/${
              index !== projects.length - 1 ? projects[index + 1].slug : projects[0].slug
            }`}
          >
            <h3>{index !== projects.length - 1 ? projects[index + 1].title : projects[0].title}</h3>
            <div>Next Project</div>
          </Link>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16">
            <path fill="none" stroke="#33323D" d="M1 0l8 8-8 8" />
          </svg>
        </div>
      </>
    );
  };

  return (
    <main id="project">
      {loading ? (
        <></>
      ) : (
        <>
          <section className="hero">
            <div className="container">
              <img src={projects[index].heroImage} alt="" />
            </div>
          </section>

          <section className="details">
            <div className="container">
              <div className="description">
                <h2>{projects[index].title}</h2>
                <p>{projects[index].description}</p>

                <span className="tech">Interaction Design / Front End Development</span>
                <span className="tech">HTML / CSS / JS</span>
                <a href={projects[index].link} className="btn secondary">
                  VISIT WEBSITE
                </a>
              </div>

              <div className="preview">
                {/* <div className="background">
              <h3>Project Background</h3>
              <p>
                This project was a front-end challenge from Frontend Mentor. It’s a platform that
                enables you to practice building websites to a design and project brief. Each
                challenge includes mobile and desktop designs to show how the website should look at
                different screen sizes. Creating these projects has helped me refine my workflow and
                solve real-world coding problems. I’ve learned something new with each project,
                helping me to improve and adapt my style.
              </p>
              <p>{projects[index].description}</p>
            </div> */}

                <div className="preview-images">
                  <h3>Static Previews</h3>
                  <img
                    style={{ maxWidth: "100%" }}
                    src={projects[index].previewImageDesktop}
                    alt=""
                  />
                  <img
                    style={{ maxWidth: "100%" }}
                    src={projects[index].previewImageMobile}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="prev-next">
            <div className="container">{renderLinks()}</div>
          </section>

          <CallToAction />
        </>
      )}
    </main>
  );
};

export default Project;
