import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import hamburger from "../images/icons/hamburger.svg";
import close from "../images/icons/close.svg";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState();

  const MobileMenu = () => {
    return (
      <nav className="mobile-menu">
        <ul>
          <li onClick={() => setMenuOpen(false)}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li onClick={() => setMenuOpen(false)}>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    );
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <h2>Boaz Anicet</h2>
        </div>
        <ul className="nav-links">
          <li>
            <NavLink activeClassName="active" exact to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/portfolio">
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/contact">
              Contact
            </NavLink>
          </li>
        </ul>
        {!menuOpen ? (
          <div className="hamburger" onClick={() => setMenuOpen(true)}>
            <img src={hamburger} alt="" />
          </div>
        ) : (
          <div className="hamburger" onClick={() => setMenuOpen(false)}>
            <img src={close} alt="" />
          </div>
        )}

        {menuOpen && <MobileMenu />}
      </div>
    </header>
  );
};

export default Header;
