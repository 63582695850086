import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <section className="cta">
      <div className="container">
        <h2>Interested in doing a project together?</h2>
        <div className="line">&nbsp;</div>
        <Link to="/contact" className="btn secondary">
          CONTACT ME
        </Link>
      </div>
    </section>
  );
};

export default CallToAction;
