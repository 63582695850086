import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./utils/ScrollToTop";

const App = () => {
  return (
    <ScrollToTop>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/portfolio/:project" component={Project} />
        <Route exact path="/contact" component={Contact} />
        <Route path="*" component={Home} />
      </Switch>
      <Footer />
    </ScrollToTop>
  );
};

export default App;
