import React from "react";
import { Link } from "react-router-dom";
import CallToAction from "../components/CallToAction";
import hero from "../images/homepage/desktop/image-homepage-hero.jpg";
import profile from "../images/homepage/desktop/image-homepage-profile.jpg";
import code from "../images/homepage/desktop/code.jpg";

const Home = () => {
  return (
    <main id="index">
      <section className="hero">
        <div className="container">
          <div className="content">
            <img src={hero} alt="" />
            <div className="text">
              <h1>Hey, I’m Boaz Anicet and I love building beautiful websites</h1>
              <a className="btn primary" href="#about">
                About Me
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container">
          <div className="content">
            <img className="image" src={code} alt="" />
            <div className="text">
              <h2>About Me</h2>
              <div>
                I’m a front-end developer looking for a new role in an exciting company. I focus on
                writing accessible HTML, using modern CSS practices and writing clean JavaScript.
                When writing JavaScript code, I mostly use React, but I can adapt to whatever tools
                are required. I'm currently looking for remote only positions. I’d love for you to
                check out my work.
              </div>
              <Link to="/portfolio" className="btn secondary">
                GO TO PORTFOLIO
              </Link>
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </main>
  );
};

export default Home;
